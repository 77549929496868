<template>
  <base-section id="mision">
    <v-container>
     <!-- <v-row>
        <v-col>
          <h3 class="display-1  mb-0 text-left red--text text-center mb-4">
            CONSEJO MEXICANO DE PATOLOGÍA CLÍNICA Y MEDICINA DE LABORATORIO, A. C.
          </h3>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">

            <div align="center">
              <a :href="card.ruta">
                <v-img  :src="card.src" style="max-height: 120px; max-width: 120px"/>
              </a>

            </div>
            <div>
              <div
                class="text-uppercase mt-4"
                space="3"

              >
                {{card.title}}
              </div>
            </div>

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
            <h2 class="font-weight-bold mb-0 text-left red--text text-center mb-4">
              <!--Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C.-->
              CONSEJO MEXICANO DE PATOLOGÍA CLÍNICA Y MEDICINA DE LABORATORIO, A. C.
            </h2>
          <base-title
            class="primary--text"
            title="MISION"
            tag="div"
          />
          <div class="font-weight-bold mb-4" style="text-align: justify; color: #636363">
            <p>La misión del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio, A. C. como miembro del Comité Normativo Nacional de Consejos de Especialidades Médicas, A. C. es la promoción de la certificación y la recertificación de los médicos especialistas en Patología Clínica por medio de las siguientes actividades:</p>
          </div>

          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">

                <v-icon
                  class="mr-3"
                  size="28"
                  color="green"
                >
                  mdi-check-circle-outline
                </v-icon>
                <div
                  v-if="text"
                  class=""
                  v-text="text"
                />
                <!--<base-list-item :text="text" />-->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMision',

    data: () => ({
      multiply: 6,

      cards: [
        {
          title: 'CERTIFICACION',
          icon: 'mdi-mouse',
          outlined: true,
          src: require('@/assets/compac/cert.svg'),
          ruta:'/certificacion'
        },
        {
          title: 'RECERTIFICACION',
          color: 'primary',
          dark: true,
          icon: 'mdi-headphones',
          src: require('@/assets/compac/recert.svg'),
          ruta:'/recertificacion'
        },
        {
          title: 'EDUCACION CONTINUA',
          icon: 'mdi-vuetify',
          outlined: true,
          src: require('@/assets/compac/edu.svg'),
          ruta:'/educacion-continua'
        },
      ],
      marketing: [
        'Establecer los estándares para la evaluación de la certificacion',
        'Establecer los parámetros para la calificación de la educación continua',
        'Establecer los procedimientos para la revalidación de la certificacion',
        'Establecer una comunicación continua con las unidades médicas formadoras de especialistas',
        'Mantener un registro actualizado de los especialistas certificados y revalidados en Patología Clínica',
        'Colaborar con la autoridad en la información de Patología Clínica a través del CONACEM',
        'Colaborar con las Universidades y unidades médicas formadoras de especialistas en la  elaboración y/o modificación de los programas académicos de la especialidad',

      ],
    }),
    computed: {
      classes () {
        return [
          this.outlined && 'base-avatar--outlined',
        ]
      },
      isDark () {
        return this.theme.isDark || this.dark
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)

        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
    },
  }
</script>


<style lang="sass">
  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &.base-avatar--outlined .base-avatar__avatar
      border-width: thin
      border-style: solid

    .theme--light .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(0, 0, 0, .12) !important

    .theme--dark .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(255, 255, 255, .12) !important
</style>
